<template>
	<section class="quiz__content">
		<v-container>
			<v-row class="align-center">
				<v-col cols="12">
					<h2 class="quiz__content--name">Quiz</h2>
					<component
						:is="activeQuestionComponent"
						:content="activeQuestionContent"
						:responses="activeQuestionResponses"
						:questionId="activeQuestionId"
						:index="activeQuestionIndex + 1"
						:selectedAnswers="selectedAnswers"
						@addSelectedAnswer="$emit('addSelectedAnswer', $event)"
					></component>
					<form-message v-if="message">{{ message }}</form-message>
					<div class="quiz-buttons-container">
						<v-btn
							class="quiz-butotn-previous"
							v-if="stage !== 0"
							:loading="isLoading"
							@click="prevQuestion"
							>Previous question</v-btn
						>
						<v-btn
							class="quiz-button-next"
							v-if="stage !== 2"
							:loading="isLoading"
							@click="nextQuestion"
							>Next question</v-btn
						>
						<v-btn
							class="quiz-button-next"
							v-if="stage === 2"
							:loading="isLoading"
							@click="completeQuiz"
							>Complete quiz</v-btn
						>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</section>
</template>

<script>
import QuizMultipleChoices from "../../blocks/quiz/QuizMultipleChoices.vue";
import QuizTrueFalse from "../../blocks/quiz/QuizTrueFalse.vue";
import QuizFreeForm from "../../blocks/quiz/QuizFreeForm.vue";
import { studentBlock } from "@/util/quiz/studentBlock";
import { post } from "@/util/requests/post";
import { mapGetters } from "vuex";
import { put } from "@/util/requests/put";
import { get } from "@/util/requests/get";

export default {
	name: "QuizContent",
	components: {
		QuizMultipleChoices,
		QuizTrueFalse,
		QuizFreeForm
	},
	props: {
		quiz: {
			type: Object,
			require: true
		},
		selectedAnswers: {
			type: Object,
			require: true
		}
	},
	computed: {
		...mapGetters(["startedCourses", "isLoading", "userId"]),
		activeQuestionIndex() {
			if (!!this.quiz === false) return;
			return this.quiz?.questions.findIndex(
				el => el.question.id === Number(this.$route.query.question)
			);
		},
		studentCourse() {
			return this.startedCourses.find(
				el => el.id === Number(this.$route.query.studentCourse)
			);
		},
		originQuestion() {
			return this.quiz?.questions[this.activeQuestionIndex]?.question;
		},
		originQuestionId() {
			return Number(this.$route.query.question);
		},
		activeQuestion() {
			if (!!this.quiz === false) return;
			if (this.condQuestion) {
				return this.condQuestion;
			}
			return this.quiz?.questions[this.activeQuestionIndex]?.question;
		},
		isConditionPossible() {
			return this.originQuestion?.responses?.some(
				el => el.cond_questions !== null
			);
		},
		activeQuestionId() {
			return Number(this.activeQuestion?.id);
		},
		activeQuestionContent() {
			return this.activeQuestion?.content;
		},
		activeQuestionResponses() {
			return this.activeQuestion?.responses;
		},
		activeQuestionComponent() {
			return this.setComponent(this.activeQuestion?.type);
		}
	},

	data() {
		return {
			rules: {
				required: value => !!value || "Required."
			},
			pageContent: this.page?.content,
			newPageTitle: "",
			correctComponent: "",
			stage: 0,
			condQuestion: null,
			message: ""
		};
	},
	watch: {
		activeQuestionIndex: function (val) {
			this.checkStage(val);
		}
	},
	methods: {
		checkStage(val) {
			if (val === 0) {
				this.stage = 0;
			} else if (
				val + 1 === this.quiz?.questions.length &&
				!this.isConditionPossible
			) {
				this.stage = 2;
			} else {
				this.stage = 1;
			}
		},
		setComponent(type) {
			return studentBlock[String(type)];
		},
		handleConditionQuestion(condQuestion) {
			if (condQuestion === null) return;
			this.condQuestion = condQuestion[0].question;
		},
		async nextQuestion() {
			if (!this.selectedAnswers[this.activeQuestionId]) {
				this.message = "Please select an answer";
				return;
			}
			this.message = "";
			if (!this.condQuestion) {
				const condQuestion = this.originQuestion.responses.find(
					el => el.id === this.selectedAnswers[this.originQuestionId]
				)?.cond_questions;

				if (
					this.activeQuestionIndex + 1 ===
						this.quiz?.questions.length &&
					!!this.activeQuestion.responses.find(
						el => this.selectedAnswers[this.activeQuestionId]
					).cond_questions === false
				) {
					await this.sendResult(
						this.selectedAnswers[this.activeQuestionId]
					);
					this.stage = 2;
					return;
				} else if (condQuestion) {
					this.handleConditionQuestion(condQuestion);
					this.sendResult(
						this.selectedAnswers[condQuestion[0].question.id]
					);
					return;
				}
			}

			this.condQuestion = null;
			await this.$store.dispatch("setIsLoading", true);
			await this.sendResult(this.selectedAnswers[this.activeQuestionId]);
			await this.$store.dispatch("setIsLoading", false);
			this.$router.push({
				query: {
					...this.$route.query,
					quiz: this.$route.query.quiz,
					question:
						this.quiz?.questions[this.activeQuestionIndex + 1]
							.question_id
				}
			});
		},
		async sendResult(responseId) {
			try {
				let responseIds = [];
				if (typeof responseId !== "object") {
					responseIds.push(responseId);
				} else {
					responseIds = responseId;
				}
				await Promise.all(
					responseIds.map(async id => {
						const response = this.activeQuestion.responses.find(
							el => el.id === id
						);
						post("/result", {
							report_id: this.studentCourse.report_id,
							quiz_id: this.quiz.id,
							quiz_name: this.quiz.name,
							question_id: this.activeQuestionId,
							question: this.activeQuestionContent,
							response_id: id,
							response: response.content,
							explanation: "",
							risk_score: response.risk_score,
							result_data: "",
							debug_data: ""
						});
					})
				);
			} catch (error) {
				console.error(error);
				await this.$store.dispatch("setIsLoading", false);
			}
		},
		prevQuestion() {
			if (this.activeQuestionIndex !== 0) {
				this.$router.push({
					query: {
						...this.$route.query,
						quiz: this.$route.query.quiz,
						question:
							this.quiz?.questions[this.activeQuestionIndex - 1]
								.question_id
					}
				});
			}
		},
		async clearQuiz() {
			localStorage.removeItem(`quizAnswers[${this.$route.query.quiz}]`);
			localStorage.removeItem(`quizData[${this.$route.query.quiz}]`);
			localStorage.removeItem(`courseData[${this.quiz.courses[0].id}]`);
			localStorage.removeItem(`reportId[${this.quiz.courses[0].id}]`);
		},
		async completeQuiz() {
			const reportId = localStorage.getItem(
				`reportId[${this.quiz.courses[0].course_id}]`
			);
			console.log(this.quiz.courses[0].course_id);
			try {
				await this.$store.dispatch("setIsLoading", true);
				await get(`/report_complete/${this.studentCourse.report_id}`);
				await get(`/student_course_complete/${this.studentCourse.id}`);
				this.clearQuiz();
				await this.$store.dispatch("getCourses");
				await this.$store.dispatch("getQuizzes");
				await this.$store.dispatch("getStartedCourses");
				await this.$store.dispatch(
					"getStudentAnalytics",
					`?filters=user_id&filter_values=${this.userId}`
				);
				await this.$store.dispatch("getStudentAnalytics");
			} catch (error) {
				console.error(error);
			}
			await this.$store.dispatch("setIsLoading", false);

			await this.$router.push(`/student/results?course=${reportId}`);
		}
	},
	beforeMount() {
		this.checkStage(this.activeQuestionIndex);
	}
};
</script>

<style lang="scss" scoped>
.quiz-button {
	&s-container {
		display: grid;
		grid-template-columns: auto auto;
		grid-template-rows: auto;
		grid-template-areas: "previous next";
		justify-content: space-between;
		align-items: center;
	}
	&-next {
		grid-area: next;
		max-width: 200px;
	}
	&-previous {
		grid-area: previous;
		max-width: 200px;
	}
}
</style>
